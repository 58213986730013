// A/B sticky jumplinks test css

.no-csstransforms .sticky-landing-cta-header {
opacity: 0;
}

.sticky-landing-cta-header {
position: fixed;
top: 0;
right: 0;
left: 0;
z-index: 500;
transform: translate(0, -100%);
transition: transform 0.3s ease-out;
opacity: 0;
}

.show-sticky-landing-cta-header .sticky-landing-cta-header {
opacity: 1;
transform: translate(0, 0);
}

.remove-sticky-landing-cta-header .sticky-landing-cta-header {
opacity: 1;
transform: translate(0, -100%);
}

.no-csstransforms .show-sticky-landing-cta-header .sticky-landing-cta-header {
display: block;
opacity: 1;
}
.ga-btn-anchor, .ga-sticky-btn-anchor, .tp-ga-btn-anchor, .tp-ga-sticky-btn-anchor  {
	border-color: transparent;
	color: #ffffff;
	line-height: 1.1;
	padding: 15px 10px;
	position: relative; 
	text-decoration: none !important;
}
.ga-btn-anchor  {
	background-color: transparent;
      text-transform: none;
      border: 1px solid #f9f9f9;
}
.ga-sticky-btn-anchor  {
	  background-color: #1d3455;
}
.tp-ga-btn-anchor, .tp-ga-sticky-btn-anchor  {
	background-color: #748193;
}
.ga-btn-anchor:hover, .ga-sticky-btn-anchor:hover, .ga-btn-anchor.active, .ga-sticky-btn-anchor.active, .tp-ga-btn-anchor:hover, .tp-ga-sticky-btn-anchor:hover, .tp-ga-btn-anchor.active, .tp-ga-sticky-btn-anchor.active  {
	background-color: #ffffff;
	color: #272727;
}
.ga-sticky-btn-anchor.active, .tp-ga-sticky-btn-anchor.active  {
	margin-bottom: 9px;
}
.ga-btn-anchor.active::after, .ga-sticky-btn-anchor.active::after, .tp-ga-btn-anchor.active::after, .tp-ga-sticky-btn-anchor.active::after{
	position: absolute;
	content: '';
	bottom:-10px;
	right:calc(50% - 10px);
	width: 0;
	height: 0;
	border-width: 10px 10px 0 10px;
	border-color: #FFFFFF transparent transparent transparent;
	border-style: solid;
}
section[id] { 
	border-top: 20px solid transparent;
	margin-top: -20px;
	-webkit-background-clip: padding-box;
	-moz-background-clip: padding;
	background-clip: padding-box;
}

@media screen and (max-width: 719px) {
.sticky-landing-cta-header a:nth-child(1), .sticky-trip-cta-header a:nth-child(1) {
	margin-left: 0 !important;
	}
.anchor-row-wrapper {
	padding: 0;
}
.anchor-row {
	margin-right: 20px !important;
}
}


// sales snippet
.expert-banner {
	opacity: 0;
	height: 0;
}
.expert-banner.left-aligned {
	margin-top: -2rem;
}

.expert-banner.opacity-100 {
-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) .3s both;
animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) .3s both;
height: auto;
}

.fade-in-top {
-webkit-animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
animation: fade-in-top 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) 1s both;
}

.arrow-left {
width: 0px;
height: 0px;
border-top: 10px solid transparent;
border-bottom: 10px solid transparent;
border-right: 10px solid #05143a;
top: .3rem;
left: -9px;
position: absolute;
}
/**
	* ----------------------------------------
	* animation fade-in-top
	* ----------------------------------------
	*/
	@-webkit-keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	}

	@keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	}

	.ping {
	-webkit-animation: ping 2.5s ease-in infinite both;
	animation: ping 2.5s ease-in infinite both;
	}

	/**
* ----------------------------------------
* animation ping
* ----------------------------------------
*/
@-webkit-keyframes ping {
0% {
	-webkit-transform: scale(0.2);
	transform: scale(0.2);
	opacity: 0.8;
}

80% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 0;
}

100% {
	-webkit-transform: scale(2.2);
	transform: scale(2.2);
	opacity: 0;
}
}

@keyframes ping {
0% {
	-webkit-transform: scale(0.2);
	transform: scale(0.2);
	opacity: 0.8;
}

80% {
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	opacity: 0;
}

100% {
	-webkit-transform: scale(2.2);
	transform: scale(2.2);
	opacity: 0;
}
}