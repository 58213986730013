// custom swoop overide
// gallery carousel css
.swiper-container-horizontal > .swiper-pagination-progressbar, 
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  bottom: 0 !important;
  top: auto !important;
}
.gallery, .reviews {
  .swiper-container {
    margin-bottom: 4rem;
  }
  .swiper-button-next, .swiper-button-prev {
    background-color:rgba(29,52,85,0.5);
    transition: background-color ease-in-out 300ms;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color:rgba(29,52,85,.85);
  }
  .swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
      padding: 2.5rem 1.5rem;
      border-radius: 4px;
  }
  .swiper-pagination {
    position: relative;
  }
}

.reviews {
  .swiper-button-prev, .swiper-button-next {
    top: 35%;
  }
  .swiper-button-prev.swiper-button-white {
    left: 1px;
  }
  .swiper-button-next.swiper-button-white {
    right: 1px;
  }
}
.gallery {
  .swiper-pagination-bullet-active {
      background: rgb(230,108,40);
    }
  .swiper-slide {
    .caption {
        position: absolute;
        max-width: 500px;
        z-index: 2;
        bottom: 0;
        background-color: rgba(29,52,85,.90);
        color: #fff;
        line-height: 1;
        text-align: left;
        display: flex;
        span {
          content: url(../img/svg-icon/map-marker-wht.svg);
          height: 30px;
          width: 30px;
          margin-top: -6px;
          color: #fff;
          fill: #fff;
          opacity: 0.5;
          flex-shrink: 0;
        }
      }
  }
  .swiper-slide.swiper-slide-active {
    .caption {
      transition-delay: 500ms;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
              animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  } 
  .swiper-slide img {
    width: auto;
    max-height: 600px;
  }
  &.full-screen{
    .swiper-slide {
      max-height: 90vh;
    }
  }
}
// customer quotes css
.quotes, .swoop_team {
  &.swiper-container {
    max-width: 1024px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
  }
}
.quotes, .quote, .swoop_team {
  blockquote {
    padding: 0 2.5rem;
  }
  .avatar-img {
    background: rgba(255,255,255,.3);
    border-radius: 50%;
    &::before {
      // content: url(../img/svg-icon/trekking.svg);
      display: block;
      height: 100px;
      width: 100px;
      position: absolute;
      top: 30px;
      right: 30px;
      opacity: 0.5;
    }
    img {
      transform: scale(0.95);
    }
  }
}
.quotes, .quote, .swoop_team{
  .quote-content p {
    // font-size: 1.3rem;
    line-height: 1.3;
    padding: 0 4rem;
  }
}
.quote , .swoop_team {
  .avatar-img {
    width: 120px;
    height: 120px;
  }
}

// trips
.trips, .you-may-like {
  &.swiper-container {
    overflow: hidden !important;
    padding-bottom: 2rem;
  }
  .swiper-slide {
    .summary-card-img-crop {
      position: relative;
      overflow: hidden;
    }
    &.swiper-slide-active {
      .summary-card-img {
          > a picture{
          &:hover {
            &::after {
              opacity: 1;
            }
          }
          &::after {
            content: "View trip";
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
            font-family: "Montserrat", helvetica, sans-serif;
            font-size: 1rem;
            letter-spacing: 1px;
            width: 100%;
            height: 100%;
            transition: opacity .3s ease;
            opacity: 0;
            background-color: rgba(29,52,85,.7);
            color: #fff;
          }
        }
      }
    }
    @media screen and (max-width: 1100px) and (min-width: 800px), (min-width: 1100px) { 
        .summary-card-content.has-content {
          min-height: 345px;
        }
    }

  }
  .swiper-button-next, .swiper-button-prev {
    background-color:rgba(29,52,85,0.2);
    transition: background-color ease-in-out 300ms;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color:rgba(29,52,85,.85);
  }
  .swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
      padding: 2rem 1.5rem;
      border-radius: 4px;
  }
  .swiper-pagination.swiper-pagination-bullets{
    bottom: 0px;
  }
  .swiper-pagination-bullet {
    transition: transform ease-in 100ms;
  }
  .swiper-pagination-bullet:hover {
    transform: scale(1.4);
  }
  .swiper-pagination-bullet-active {
    background:rgba(29,52,85,.9)
  }
}

.swiper-slide:hover i.transition {
  animation: popIn 0.3s linear;
  animation-fill-mode: both;
}
.swiper-slide:hover i.transition:nth-child(1) { animation-delay: .1s }
.swiper-slide:hover i.transition:nth-child(2) { animation-delay: .2s }
.swiper-slide:hover i.transition:nth-child(3) { animation-delay: .3s }
.swiper-slide:hover i.transition:nth-child(4) { animation-delay: .4s }
.swiper-slide:hover i.transition:nth-child(5) { animation-delay: .5s }

@keyframes popIn { 
  0% {
    transform: scale(.1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * media query
 * ----------------------------------------
 */

 // md devices
@media (max-width: 768px) {
  .quotes, .quote, .swoop_team {
    .avatar-img {
      width: 100px;
      height: 100px;
      &::before {
        height: 70px;
        width: 70px;
        position: absolute;
        top: 15px;
        right: 15px;
        opacity: 0.5;
      }
    }
  } 
}
// sm devices
@media (max-width: 640px) {
  .reviews {
    .swiper-button-prev, .swiper-button-next {
      display: none;
    }
  }
  .gallery {
    .swiper-slide .caption {
      span {
        margin-top:-5px;
        margin-right: 1px;
        height: 20px;
        width: 20px;
      }
    }
    &.full-screen {
      .swiper-slide .caption {
        span {
          margin-top:-5px;
          margin-right: 1px;
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .quotes, .quote, .swoop_team {
    blockquote {
      padding: 0 2.5rem;
      .quote-content p {
        font-size: 16px;
        padding: 0 20px;
      }
    }
  }
  .trips, .you-may-like {
    &.swiper-container {
      padding-bottom: 0.3rem;
    }
    .swiper-pagination.swiper-pagination-bullets{
      bottom: 15px;
    }
    .swiper-slide {
      transform: scale(.95) !important;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

}
.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
            opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
            opacity: 1;
  }
}

.swiper.carousel {
  .swiper-scrollbar.swiper-scrollbar-horizontal {
    background: rgba(255, 255, 255, .1);
    height: 10px;
    bottom: -2rem;

    .swiper-scrollbar-drag {
      background: rgba(255, 255, 255, .7);
    }
  }
}
