// .new-trip-summary {
//   font-size: 1.1rem;
//   ul {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     list-style: none;
//     margin-left: 0;
//   }
//   li {
//     font-size: 1.75rem;
//     line-height: 1.25;
//     width: 33%;
//     @media (max-width: 640px) {
//       width: 100%;
//     }
//     padding: 0 1rem 2rem 5rem;
//     &:before {
//       margin: 0 .7em 0 -4rem;
//       content: url(/assets/front/dist/img/svg-icon/wht-info.svg);
//       display: inline-block;
//       height: 25px;
//       width: 25px;
//       position: relative;
//       top: 10px;
//       opacity: 0.4;
//     }
//   }
// }
// .trip-nav {
//   &.more-content::after {
//     margin-top: -40px;
//   }
//   &.more-content::before {
//     content: "";
//     display: block;
//     width: 60px;
//     height: 60px;
//     background-color:$bg-swoop;
//     position: absolute;
//     top: 0;
//     left: 50%;
//     margin-left: -30px;
//     margin-top: -50px;
//     border-radius: 50%;
//   }
// }
.trip-img-thumb {
  .swiper-slide {
  background-color: rgba(28, 38, 96, 0.85);
  border-radius: 5px;
  overflow: hidden;
  opacity: 0.5;
  cursor: pointer;
  height: auto;
  border: 3px solid #ffffff;
  &:hover {
    opacity: 0.9;
  }
  }
  .swiper-slide-thumb-active {
  opacity: 1;
  border-color: darkorange;
  }
}
.trip-img {
  .swiper-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    background: $bg-swoop;
  }

  .swiper-slide {
    img{
      min-width: 620px;
      min-height: 340px;
      vertical-align: middle;
    }
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    &::after { 
      display: block;
      position: absolute;  
      bottom: 0;
      left: 0;
      height: 100px;
      width: 100%;
      content: '';   
      background-image: linear-gradient(180deg, rgba(3, 22, 47, 0), rgba(3, 22, 47, 0.9))
    }
    // .caption {
    //     position: absolute;
    //     max-width: 500px;
    //     z-index: 2;
    //     bottom: 0;
    //     left: 2rem;
    //     color: #fff;
    //     margin: 0;
    //     font-size: 2rem;
    //     line-height: 1;
    //     text-align: left;
    //     display: flex;
    //     justify-content: center;
    //     span {
    //       content: url(../img/svg-icon/map-marker-wht.svg);
    //       height: 30px;
    //       width: 30px;
    //       margin-right: 3px;
    //       margin-top: -8px;
    //       color: #fff;
    //       fill: #fff;
    //       opacity: 0.5;
    //     }
    //   }
  }
  .swiper-slide.swiper-slide-active {
    .caption {
      transition-delay: 500ms;
      -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
              animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }
  }
  .swiper-button-next, .swiper-button-prev {
    background-color:rgba(29,52,85,0.5);
    transition: background-color ease-in-out 300ms;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color:rgba(29,52,85,.85);
  }
  .swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
      padding: 2rem 1.5rem;
      border-radius: 4px;
  }
}

// .itinerary-map {
//   .title-icon span {
//     padding-left: 40px;
//     &::before {
//       background-position: -1376px 0 !important;
//       width: 32px;
//       height: 32px;
//     }
//   }
//   .map-canvas {
//     height: 500px;
//   }
//   .gradient {
//     position: relative;
//     ul {
//       font-size: 1.8rem;
//     }
//     &::after {
//       display: block;
//       position: absolute;
//       bottom: 0;
//       left: 0;
//       height: 150px;
//       width: 100%;
//       content: '';
//       background-image: linear-gradient( 180deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.9));
//     }
//   }
//   .scroll-text {
//     height: 510px;
//     overflow-y: auto;
//     overscroll-behavior-y: auto;
//     padding: 0 2rem 2rem 1rem;
//     p:nth-last-child(1) {
//       padding-bottom: 100px;
//     }
//   }
//   h4 {
//     font-size: 2.5rem;
//     background-color: #EFEFEF;
//     padding: 1rem 2rem;
//     color: #041E42;
//   }
//   p {
//     font-size: 16px;
//   }
//   hr {
//     background: #ccc;
//   }
// }

// .reviews {
//   .bg-icon {
//     position: relative;
//     &::before {
//       content: url(../img/svg-icon/user.svg);
//       display: block;
//       height: 40px;
//       width: 40px;
//       position: absolute;
//       top: 3px;
//       right: 4px;
//       opacity: 0.2;
//     }
//   }
//   &.swiper-container {
//     position: relative;
//     &::before {
//       display: block;
//       position: absolute;
//       top: 0;
//       right: 0;
//       width: 130px;
//       height: 100%;
//       content: '';
//       background-image: linear-gradient(90deg, rgba(72, 169, 197, 0), rgb(72, 169, 197));
//       z-index: 5;
//     }
//   }
//   .swiper-button-next, .swiper-button-prev {
//     background-color:rgba(29,52,85,0.5);
//     transition: background-color ease-in-out 300ms;
//   }
//   .swiper-button-next:hover, .swiper-button-prev:hover {
//     background-color:rgba(29,52,85,.85);
//   }
//   .swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
//       padding: 4rem 2.5rem;
//       border-radius: 4px;
//   }
//   .swiper-button-prev {
//     display: none;
//   }
//   .swiper-pagination.swiper-pagination-bullets{
//     bottom: 0px;
//   }
//   .swiper-slide > div {
//     max-width: 77%;
//   }
// }
// .vessel-summary, .trip-tabs {
//   font-size: 1.1rem;
//   ul {
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: flex-start;
//     list-style: none;
//     margin-left: 0;
//   }
//   li {
//     line-height: 1.25;
//     width: 50%;
//     @media (max-width: 640px) {
//       width: 100%;
//     }
//     padding: 0 1rem 2rem 5rem;
//     &:before {
//       margin: 0 .7em 0 -4rem;
//       display: inline-block;
//       height: 25px;
//       width: 25px;
//       position: relative;
//       top: 10px;
//     }
//   }
// }
// .vessel-summary {
//   li {
//     &:before {
//       content: url(/assets/front/dist/img/svg-icon/navy-info.svg);
//       opacity: 0.4;
//     }
//   }
// }

// .trip-tabs {
//   hr {
//     background: #ccc;
//     margin-bottom: 2rem;
//   }
//   .inc {
//     li {
//       &:before {
//         content: url(/assets/front/dist/img/svg-icon/green-tick.svg);
//         opacity: 0.8;
//       }
//     }
//   }
//   .exc {
//     li {
//       &:before {
//         content: url(/assets/front/dist/img/svg-icon/red-close.svg);
//         opacity: 0.8;
//       }
//     }
//   }
// }

// .bg-light-gray {
//   background-color: #f9f9f9;
// }
// #trip-add-ons {
//   .add-on-activity-icon {
//       top: -5px;
//       max-height: 25px;
//       font-size: 10px;
//       line-height: 0.9;
//   }
// }

// ^^ comenting out trip page a/b test css as it's not longer running
#month-scroll-container {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}
#dates-prices {
  .trips-table-cruise {
    .table {
      &.table-auto {
        tr.title {
          .col-cta {
            background-color: #8c97a2 !important;
          }
        }
        .arrows {
          display: none;
        }
        .graident::after {
          background-image: none; 
        }
      }
      scroll-snap-align: start;
      scroll-snap-stop: normal;
      thead tr {
        background-color: #edf2f7;
      }
      thead th {
        height: auto;
        width: 110px;
        font-size: 13px;
        line-height: 1.3;
        text-align: center;
        padding:4px 10px;
        font-weight: 600;
        border: 1px solid #cbd5e0;
        
      }
      tbody {
        border: 1px solid #e2e8f0;
        font-size: 14px;
        td {
          text-align: center;
          padding: 15px 10px;
          line-height: 1.2;
          // a {
          //   color: rgb(173, 173, 173);
          // }
        }
        tr.title td {
          text-align: left;
          padding-left: 15px;
          font-weight: 600;
        }
        .currency-amount, .col-date > a{
          color: #4a5568;
          &:hover{
            color: #ed8936;
          }
        }
        .currency-amount {
          font-size: 14px;
        }
        .currency-symbol {
          font-size: 12px;
          color: #4a5568;
          padding-right: 3px;
        }
        .currency-amount, .currency-symbol {
          display: inline;
        }
        .text-small {
          .currency-amount, .currency-symbol {
            font-size: .8rem;
          }
        }
        .sale-tag {
          padding: 0.1rem 0.25rem; 
          font-size: 0.8em;
          border-radius: 0.1rem;
        }
      }
      .even td, .even .sale-tag{
        background-color: #f7f7f7;
      }
    }
  }
}
.date-picker {
  .swiper-button-next, .swiper-button-prev {
    background-color:rgba(230,108,40,.7);
    transition: background-color ease-in-out 300ms;
    top: 43%;
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next:hover, .swiper-button-prev:hover {
    background-color:rgba(230,108,40,1);
  }
  .swiper-button-next.swiper-button-white, .swiper-button-prev.swiper-button-white {
      border-radius: 0;
      height: 50%;
  }
  .swiper-button-prev::after, .swiper-button-next::after {
    font-size: 20px;
  }
  .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
    opacity: 0;
  }
}

.arrows {
	width: 30px;
	height: 30px;
	position: absolute;
	left: 40px;
	bottom: 20px;
  margin-left: -20px;
  overflow: visible;
}

.arrows path {	
	animation: arrow 4s infinite;
	-webkit-animation: arrow 4s infinite; 
}

@keyframes arrow
{
0% {opacity:0; transform:translatex(10px)}
80% {opacity:1; transform:translatex(50px)}
100% {opacity:0; transform:translatex(50px)}
}

@-webkit-keyframes arrow /*Safari and Chrome*/
{
0% {opacity:0; transform:translatex(10px)}
80% {opacity:1; transform:translatex(50px)}
100% {opacity:0; transform:translatex(50px)}
}

.arrows path.a1 {
	animation-delay:-0.9s;
	-webkit-animation-delay:-0.9s; /* Safari and Chrome */
}

[x-cloak] { display: none; }
.trips-itinerary-viewer > h4 > i {
  font-size: 1.1rem;
}
@media (max-width: 640px) {
  .trip-img .swiper-slide {
    img{
      min-height: 100%;
      min-width: 100%;
    }
  }
  .reviews.swiper-container::before {
    width: 70px;
  }
  .trip-img .swiper-slide::after {
    height: 100px;
  }
  .trip-nav a:nth-child(1) {
    margin-left: 10px;
  }
  .sticky-trip-cta-header a:nth-child(1) {
    margin-left: 10px;
  }
  .trip-nav.more-content::before {
    display: none;
  }
  .trip-img {
    .swiper-slide .caption {
      font-size: 12px;
      left: 1rem;
      span {
        margin-top:-5px;
        margin-right: 1px;
        height: 20px;
        width: 20px;
      }
    }

  }
}
@media (max-width: 719px) {
  #dates-prices {
    .trips-table-cruise .table tbody {
      tr.title td {
        padding: 15px 0 15px 10px;
      }
      tr td:nth-child(1) {
          font-weight: 600;
        }
      td {
        padding: 12px 10px 12px;
        text-align: right;
        &::before {
          font-size: 15px;
          line-height: 0;
          display: block;
          font-weight: 400;
        }
        // a {
        //   float: right;
        //   padding-right: 10px;
        // }
        // &.col-cta{
        //   display: flex;
        //   flex-direction: column;
        // }
      }
      td.col-date {
        padding: 10px 10px 26px;
        a {
          float: left;
        }
      }
      // td .cta {
      //   padding: 0 10px;
      //   a.btn  {
      //     color: #fff;
      //     background-color: #ed8936;
      //     padding: 10px 15px;
      //     margin-top: 5px;
      //     width: 100%;
      //     &.btn-text::after {
      //       background-size: 0;
      //     }
      //   }
      // }
      .currency-amount {
        font-size: 15px;
      }
    }
    .table {
      tr.title td.hidden {
        display: none;
      }
    }
  }
}
@media (min-width: 1024px) {
  .trip-nav a, .trip-banner-wrapper a {
    min-width: 160px;
    text-align: center;
  }
  // .reviews .swiper-slide > div {
  //   min-height: 230px;
  // }
}

@media (min-width: 720px) {
  #dates-prices {
    .trips-table-cruise .table .col-cta {
      width: 120px !important;
      border: 1px solid #cbd5e0;
      // .cta {
      //     float: left;
      //     padding-left: 8px;
      //     a {
      //       font-size: 14px;
      //       font-weight: bold;
      //       color: #ed8936;
      //     }
      // }
    }
    // .trips-table-cruise .table.table-auto .col-cta {
    //   .cta {
    //     padding-left: 20px !important;
    //   }
    // }
    .table {
      border-right: 1px solid #cbd5e0;
      td, .even td {
        border-right: 1px solid #cbd5e0;
      }
    }
  }
  .graident {
    margin-right: 10em;
      &::after { 
        display: block;
        position: absolute;  
        top:0;
        left: -24px;
        height: 100%;
        width: 20%;
        content: '';   
        background-image: linear-gradient(90deg, rgba(139, 151, 162, 0), rgba(139, 151, 162, 0.3))
      }
    }
}

// xl devices
@media (min-width: 1280px) {
  .show-mobile {
    display: none;
  }
}
// lg devices
@media (max-width: 1024px) {
  .show-mobile {
    display: none;
  }
}
// md devices
@media (max-width: 768px) {
  .split-input div.form-group {
    width: 100%;
  }
  body.trip-page {
        .trip-banner-wrapper.bg-swoop-secondary {
          padding-top: 5rem;
          padding-bottom: 2rem;
        }
        .scroller-inner .trip-banner-wrapper.bg-swoop-secondary {
          padding-top: 10rem;
        }
        .trip-banner-content > p {
          font-size: 1.1rem;
        }
        .main-nav ul > li {
          font-size: 1rem;
        }
        .js-trip-title {
          margin-bottom: 10px;
        }
  }
  .show-desktop {
    display: none;
  }
  .show-mobile {
    display: block;
  }
}

// sm devices
@media (max-width: 640px) {
  .enquiry-form {
    padding: 20px !important;
  }
  .enquiry-form.streamlinedForm {
    label[for=firstName],
    label[for=email],
    label[for=tel] {
      display: none;
    }
  }
  label,
  .form-control {
    font-size: 16px;
  }
  .show-mobile {
    display: block;
  }
  body.trip-page {
    .trip-banner-wrapper.bg-swoop-secondary {
      padding-top: 4rem;
    }
    .scroller-inner .trip-banner-wrapper.bg-swoop-secondary {
          padding-top: 5rem;
    }
  }
}
// ipad nav fix
// @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
//   .ipad-fix {
//     padding-bottom: 11rem;
//   }
//   .desktop-nav__tel.tel {
//     padding-right: 6rem;
//   }
// }