/* new font css*/
@import url("https://p.typekit.net/p.css?s=1&k=ibx1eyz&ht=tk&f=32226.32227.32230.32231.51975&a=930642&app=typekit&e=css");

@font-face {
font-family:"din-2014";
src:url("https://use.typekit.net/af/570287/00000000000000007735afea/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/570287/00000000000000007735afea/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/570287/00000000000000007735afea/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"din-2014";
src:url("https://use.typekit.net/af/c2b6e5/00000000000000007735afee/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/c2b6e5/00000000000000007735afee/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/c2b6e5/00000000000000007735afee/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"din-2014";
src:url("https://use.typekit.net/af/efa8e9/00000000000000007735aff4/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/efa8e9/00000000000000007735aff4/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/efa8e9/00000000000000007735aff4/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"din-2014";
src:url("https://use.typekit.net/af/1fe1ce/00000000000000007735aff6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/1fe1ce/00000000000000007735aff6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/1fe1ce/00000000000000007735aff6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"barlow";
src:url("https://use.typekit.net/af/ca928d/000000000000000077427d33/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/ca928d/000000000000000077427d33/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/ca928d/000000000000000077427d33/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"barlow";
src:url("https://use.typekit.net/af/3414d8/0000000000000000774491d5/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/3414d8/0000000000000000774491d5/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/3414d8/0000000000000000774491d5/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"barlow";
src:url("https://use.typekit.net/af/ca37e3/000000000000000077402670/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/ca37e3/000000000000000077402670/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/ca37e3/000000000000000077402670/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

// new inline SVG replacement font
@font-face {
  font-family: 'swoopicon';
  src:  url('../fonts/swoop-icons.eot?1d28f5dc');
  src:  url('../fonts/swoop-icons.eot?1d28f5dc#iefix') format('embedded-opentype'),
    url('../fonts/swoop-icons.ttf?1d28f5dc') format('truetype'),
    url('../fonts/swoop-icons.woff?1d28f5dc') format('woff'),
    url('../fonts/swoop-icons.svg?1d28f5dc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@import "site/index";
@import "content-illustrated-guide";
@import "content-newsletter-signup";
@import "content-advert";
@import "react-phone-number-input";
@import "header/header_banner/header/_index"; // Header
@import "banner/_banner"; // Banner
@import "_carbon-label"; // carbon label scss
@import "_AB-testing";
@import "_flags";
@import "_payment-page";
@import "_webinar";
@import "_lazy-img";
@import "_swiper"; // new carousel test
@import "_trip-page"; // new trippage test
@import "_new-menu"; // new menu
@import "_icomoon-font"; // new menu
@import "_trip-comparison";
@import "_call-schedule";
@import "_2024-brand-override";


// components
@import "components/tour/tour.scss";

#mfPreviewBar {
  display: none !important;
}


/*
------------------------------------------------------------------------------------ HTML / BODY

------------------------------------------------------------------------------------------------
*/
[class^="icon-"], [class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'swoopicon' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-weight: 300;
  font-size: 1rem;
  background-color: $white;
  color: $text-main;
  -ms-text-size-adjust:100%;
  -webkit-text-size-adjust:100%;
}

body,
p,
input,
textarea,
select,
button.btn-secondary,
.btn.btn-secondary,
button.btn-anchor,
.btn.btn-anchor,
button.btn-text,
.btn.btn-text,
.map h4,
.map p,
.chosen-container-multi .chosen-choices li.search-field input[type="text"],
.btn.icon-menu,
.qtip-content,
.sw-body-font,
.font-serif {
  font-family: "barlow","Arial Narrow";
}

.btn-logout {
  margin-right: 0;
}

i,
em,
blockquote .quote-content,
.banner-credit, b i,
strong i,
b em,
strong em,
i b,
i strong,
em b,
em strong {
  font-family: "barlow","Arial Narrow";
  font-style: italic;
}

b, strong, .quote-name, /*.trips-itinerary h3,*/ .fact-list > li:before,
.qtip-title, .ship-facts-wrapper .trip-data-table .data-table-title p,
.customer-feedback-intro-question,
.customer-feedback-form .customer-feedback-radio-grid .control-label {
  font-family: "barlow","Arial Narrow";
  font-weight: bold;
}

button,
.btn,
.footer-contact-links,
.header-contact .tel,
.header-login-info,
.summary-card-data,
.trips-table,
.trips-table strong,
.trips-table b,
.trips-table .btn.btn-text,
.add-ons-table,
.add-ons-table strong,
.add-ons-table b,
.add-ons-table .btn.btn-text,
.trip-banner-days,
.trip-banner-price,
.currency-pick,
.content-tel,
.nav-pills,
.customer-feedback-intro-list li:before,
.customer-feedback-form.form-horizontal .rating label,
.customer-feedback-form.form-horizontal .rating-container p,
.customer-feedback-intro-grid h2 span::before,
.avatar-row .avatar-title,
.icon-wishlist,
.font-sans {
  font-family: "barlow","Arial Narrow";
  font-weight: 400;
}

h1,
h2,
h3,
h3,
h4,
h5,
h6,
.sw-title-font {
  font-family: "din-2014", verdana;
  font-weight: normal;
  font-style: normal;
}

.font-bold {
  font-weight: 700 !important;
}

/*
---------------------------------------------------------------------------------- TEXT DEFAULTS

------------------------------------------------------------------------------------------------
*/

p {
  margin: 0 0 22px 0;
  line-height: 1.5;
  position: relative;
  @include font-smoothing(on);
}

a {
  color: $link-primary;
  margin: 0;
  padding: 0;
  outline: none;
  text-decoration: none; /*border-bottom: 1px solid $link-primary;*/
  word-wrap: break-word;
}
a:hover,
a.active {
  text-decoration: underline;
}
a:focus {
  outline: none;
}

h1,
h2,
h3,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: $text-dark;
  margin: 0 0 27px 0;
  position: relative;
  -webkit-backface-visibility: hidden;
  @include font-smoothing(on);
  vertical-align: top;
  line-height: 1.2;
}
h1 a,
h2 a,
h3 a {
  text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover {
  text-decoration: none;
}

h1 {
 font-size:1.9rem;
}
h2 {
 font-size:1.7rem;
}
h3 {
 font-size:1.5rem;
}
h4 {
 font-size:1.4rem;
}

hr {
  border: 0;
  height: 1px;
  background: $text-dark;
  margin-bottom: 1.5rem;
}

p.larger,
.larger p {
  @include text-larger;
}
p.smaller,
.smaller p {
  @include text-smaller;
}

p.larger-default {
  margin-bottom: 22px;
  @media screen and (min-width: 45em) {
    //  720/16
    margin-bottom: 22px;
  }
}

ul {
  list-style-type: disc;
}

ol {
  list-style: decimal;
}
.trip-summary, .content-block-content-inner, .swoop-advice-content, .trips-table-inclusion-list {
  > ul, ol {
    -webkit-backface-visibility: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-bottom: 22px;
    line-height: 1.5;
  }
  > ul {
    margin-left: 1.2rem;
    li {
      padding-bottom: 8px;
    }
  }
  > ol {
    margin-left: 1.5rem;
    li {
      padding-bottom: 1rem;
      &::marker {
        font-weight: bold;
      }
    }
  }
  li {
    margin: 0;
  }
}

/*
----------------------------------------------------------------------------------------- LAYOUT

------------------------------------------------------------------------------------------------
*/

.row {
  @include clrfix;
  padding: 0 12px;
}
.row .row {
  padding-left: 0;
  padding-right: 0;
}

section,
.section {
  padding: 3rem 0;
  @media screen and (max-width: $break-tablet) {
    padding: 2rem 0;
  }
}

.row-centered {
  text-align: center;
}

/*
----------------------------------------------------------------------------------------- NEW FOOTER

------------------------------------------------------------------------------------------------
*/

footer {
  background-color: #05143A;
}
section.footer-bottom {
  font-size: 0.8rem;
  padding: 0 0 1.3em 0;
}
.footer-btn {
	border: none;
	background: none;
	cursor: pointer;
	display: inline-block;
	margin: 0 0 0 5px;
	letter-spacing: 1px;
	outline: none;
  color: #fff;
  position: relative;
}
.footer-btn:before {
	position: relative;
	-webkit-font-smoothing: antialiased;
  position: absolute;
	height: 100%;
  line-height: 1.4;
	color: #fff;
  left: 70%;
	opacity: 0;
	top: 2px;
}
.footer-btn:after {
	content: '';
	position: absolute;
	z-index: -1;
}
.footer-btn:hover{
  margin-left: 0;
  color: $primary;
}
.footer-btn:hover:before {
	left: calc(100% + 5px);
	opacity: 1;
}
.arrow-right:before {
	content: "ᐅ";
  color: inherit;
}
footer .form-control, #newsletter-name, #newsletter-email {
  font-size: 16px;
}

/*
----------------------------------------------- BACKGROUND COLOUR MODIFIERS / MORE CONTENT ARROW

------------------------------------------------------------------------------------------------
*/

.more-content {
  position: relative;
}

.more-content::before {
  content: "";
  display: block;
  width: 80px;
  height: 80px;
  background-color: $bg-swoop;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border-radius: 40px;
}

.more-content::after {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -30px;
  margin-top: -33px;
  content: "";
  background-repeat: no-repeat;
  opacity: 0.7;
}

// Only show the first .more-content after the banner
.more-content::before,
.more-content::after  {
  display: none;
}

.bg-primary .row-title h1,
.bg-primary .row-title h2,
.bg-swoop .row-title h1,
.bg-swoop .row-title h2,
.bg-swoop-secondary .row-title h1,
.bg-swoop-secondary .row-title h2,
.bg-press .row-title h1,
.bg-press .row-title h2,
.bg-customers .row-title h1,
.bg-customers .row-title h2,
.bg-partners .row-title h1,
.bg-partners .row-title h2 {
  color: $white;
}

// Primary (Orange)
.bg-primary,
.review-summary.bg-primary:before,
.bg-primary.more-content:before {
  background-color: $primary;
  color: $white;
}// white arrow

// Default (Grey)
.bg-grey,
.bg-grey.more-content:before {
  background-color: $bg-grey-paler;
}

.main .bg-grey:nth-child(even),
.main .bg-grey:nth-child(even).more-content:before {
  background-color: $bg-grey-pale;
}

// Swoop (Blue)
.bg-swoop,
.review-summary.bg-swoop:before,
.bg-swoop.more-content:before {
  background-color: $bg-swoop;
  color: $white;
}

// Swoop Secondary (Paler Blue)
.bg-swoop-secondary,
.review-summary.bg-swoop-secondary:before,
.bg-swoop-secondary.more-content:before {
  background-color: $bg-swoop-secondary;
  color: $white;
}

// Customers (Pale Blue)
.bg-customers,
.review-summary.bg-customers:before,
.bg-customers.more-content:before {
  background-color: $bg-customer;
  color: $white;
}

// Press (Pale Green)
.bg-press,
.review-summary.bg-press:before,
.bg-press.more-content:before {
  background-color: $bg-press;
  color: $white;
} // white arrow

// Partners (Dark Green)
.bg-partners,
.review-summary.bg-partners:before,
.bg-partners.more-content:before {
  background-color: $bg-partner;
  color: $white;
}

.bg-partners.more-content:after, .bg-press.more-content:after, .bg-customers.more-content:after, .bg-swoop-secondary.more-content:after, .bg-swoop.more-content:after, .bg-primary.more-content:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpolygon style='fill:%23ffffff' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3Cpolygon style='fill:%23ffffff' points='30,32 38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 '/%3E%3Cpath style='fill:%23ffffff' d='M30,10c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S41,10,30,10z M30,46.5c-9.1,0-16.5-7.4-16.5-16.5 S20.9,13.5,30,13.5S46.5,20.9,46.5,30S39.1,46.5,30,46.5z'/%3E%3Cpolygon style='fill:%23ffffff' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3C/svg%3E%0A");
} // white arrow

.bg-grey.more-content:after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpolygon style='fill:%23191919' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3Cpolygon style='fill:%23191919' points='30,32 38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 '/%3E%3Cpath style='fill:%23191919' d='M30,10c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S41,10,30,10z M30,46.5c-9.1,0-16.5-7.4-16.5-16.5 S20.9,13.5,30,13.5S46.5,20.9,46.5,30S39.1,46.5,30,46.5z'/%3E%3Cpolygon style='fill:%23191919' points='38.2,25.2 38.2,29.3 30,36.1 21.8,29.3 21.8,25.2 30,32 '/%3E%3C/svg%3E%0A");
} // Blue arrow

/*
-------------------------------------------------------------------------------- IMAGE GALLERIES

------------------------------------------------------------------------------------------------
*/

.gallery-wrapper {
  padding-top: 56px;
  padding-bottom: 1px;
}

/*
------------------------------------------------------------------------------------ VIDEO BLOCK

------------------------------------------------------------------------------------------------
*/

.video-container {
  max-width: 840px;
  margin: auto;
  margin-bottom: 1.5em;
}

/*
------------------------------------------------------------------------------- REVIEW SUMMARIES

------------------------------------------------------------------------------------------------
*/

.swoop-says-wrapper .review-summary {
  padding: 2rem 0 1.5rem;
  @include clrfix;
}
.review-summary {
  background-size: cover;
  position: relative;
  color: $white;
  text-align: center;
  padding: 3rem 0 4rem;
  .bg-hover {
    background-color: rgba(29, 52, 85, 0.2);
    transition: background-color 400ms linear;
    &:hover {
      background-color: rgba(29, 52, 85, 0.9);
    }
  }
}
.review-summary:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.88;
}

.avatar-row-small .avatar-img {
  max-width: 100px;
}
.avatar-row-xsmall .avatar-img {
  max-width: 80px;
}

.avatar-img {
  max-width: 100px;
  overflow: hidden;
  margin: 0 auto 22px auto;
  // padding: 2px 0 0;
  position: relative;
}

.avatar-img img,
.img-circle {
  border-radius: 50%;
  overflow: hidden;
  margin: 0;
  display: block;
  box-shadow: inset 0 0 0.5rem rgba(0, 0, 0, 0.15);
}

.review-summary .cta .btn {
  margin: 0;
}

.review-summary.bg-partners:before,
.review-summary.bg-swoop:before {
  opacity: 0.94;
}

.review-summary-split {
  position: relative;
  border-top: 1px solid $white;
  padding-top: 27px;
}
.review-summary-split .quote:first-child {
  margin-bottom: 27px;
}
.review-summary-split .quote:first-child + div {
  border-top: 1px solid $white;
  padding-top: 27px;
}

.review-summary .carousel.flickity-enabled {
  margin-bottom: 78px;
}

/*
----------------------------------------------------------------------------------------- QUOTES

------------------------------------------------------------------------------------------------
*/
.quote.quote-marks{
  max-width: 1024px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.quote-marks .quote-content {
  display: block;
  position: relative;
  min-height: 48px;
}
@media screen and (min-width: 45em) {
  .quote-marks .quote-content::before,
  .quote-marks .quote-content::after {
    width: 64px;
    height: 48px;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23ffffff' d='M26.7,19c-1.9,1-3.3,1.9-4.4,2.8c-1.1,0.9-2,1.8-2.6,2.6s-1,1.6-1.1,2.4c-0.2,0.7-0.2,1.4-0.2,2.1c0,1.2,0.5,1.8,1.6,1.8 c4.7,0,7,2.2,7,6.6c0,2.5-0.7,4.4-2.1,5.7c-1.4,1.3-3.3,2-5.8,2c-3,0-5.3-0.9-6.9-2.7c-1.6-1.8-2.3-4.3-2.3-7.8 c0-4.5,1.2-8.3,3.8-11.3c2.5-3,6.1-5.7,10.8-8.1L26.7,19z M49.8,19c-1.9,1-3.4,1.9-4.5,2.8c-1.1,0.9-2,1.8-2.5,2.6 c-0.6,0.8-1,1.6-1.2,2.4c-0.2,0.7-0.2,1.4-0.2,2.1c0,1.2,0.5,1.8,1.6,1.8c4.7,0,7,2.2,7,6.6c0,2.5-0.7,4.4-2.1,5.7 c-1.4,1.3-3.3,2-5.8,2c-3,0-5.3-0.9-6.9-2.7c-1.6-1.8-2.4-4.3-2.4-7.8c0-4.5,1.3-8.3,3.8-11.3s6.2-5.7,10.9-8.1L49.8,19z'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    opacity: 0.8;
  }

  .quote-marks .quote-content::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23ffffff' d='M33.2,41.1c1.9-1,3.3-1.9,4.4-2.8c1.1-0.9,2-1.8,2.6-2.6c0.6-0.8,1-1.6,1.1-2.4c0.2-0.7,0.2-1.4,0.2-2.1 c0-1.2-0.5-1.8-1.6-1.8c-4.7,0-7-2.2-7-6.6c0-2.5,0.7-4.4,2.1-5.7c1.4-1.3,3.3-2,5.8-2c3,0,5.3,0.9,6.9,2.7 c1.6,1.8,2.3,4.3,2.3,7.8c0,4.5-1.2,8.3-3.8,11.3c-2.5,3-6.1,5.7-10.8,8.1L33.2,41.1z M10.1,41.1c1.9-1,3.4-1.9,4.5-2.8 s2-1.8,2.5-2.6c0.6-0.8,1-1.6,1.2-2.4c0.2-0.7,0.2-1.4,0.2-2.1c0-1.2-0.5-1.8-1.6-1.8c-4.7,0-7-2.2-7-6.6c0-2.5,0.7-4.4,2.1-5.7 c1.4-1.3,3.3-2,5.8-2c3,0,5.3,0.9,6.9,2.7c1.6,1.8,2.4,4.3,2.4,7.8c0,4.5-1.3,8.3-3.8,11.3c-2.5,3-6.2,5.7-10.9,8.1L10.1,41.1z'/%3E%3C/svg%3E%0A");
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
  }
}

.quote-content,
.quote-desc {
  text-align: center;
}

.quote p {
  padding: 0 10px;
}

.quote-content p {
  font-size: 1.2em;
}

.quote-desc {
  margin-bottom: 22px;
}
.quote-desc p {
  margin-bottom: 0;
}

// ------------ TRIP CARD CAROUSEL -------------//

.currency-pick a.active,
.currency-pick a.active:hover {
  color: $primary;
  border: 1px solid $primary;
  background-color: #fff;
  text-decoration: none;
}


/*
------------------------------------------------------------------------------------- PAGE INTRO

------------------------------------------------------------------------------------------------
*/

.page-title {
  padding-top: 0;
  text-align: center;
  @include clrfix;
  border-bottom: 1px solid $text-dark;
}
.page-title h2 {
  border-top: 4px solid $text-dark;
  padding-top: 22px;
  margin-bottom: 24px;
}
.page-title-collapse {
  border-bottom: none;
}

.page-intro-wrapper {
  padding-bottom: 0;
  // height: 100vh;
  @include clrfix;
}
.page-intro-content {
  padding-top: 35px;
}
.page-intro-content p {
  @include text-larger;
}

.page-intro-with-review .page-intro-content {
  text-align: left;
}
.page-intro-quote {
  padding-top: 35px;
}

/*
--------------------------------------------------------------------------------- CONTENT BLOCKS

------------------------------------------------------------------------------------------------
*/

div.content-block {
  margin-bottom: 20px;
}

.content-block .content-block-heading h2 {
  padding-top: 25px;
  padding-bottom: 22px;
  margin-bottom: 40px;
  border-top: 4px solid $text-dark;
  border-bottom: 1px solid $text-dark;
}

.content-block.guide-info-block .content-block-heading h3 {
  padding-top: 22px;
  padding-bottom: 20px;
  border-top: 4px solid $text-dark;
  border-bottom: 1px solid $text-dark;
}

.content-block.bl-close {
  padding-top: 0;
}

.heading-centered {
  text-align: center;
}

.lightbox {
  display: block;
}

/*
-------------------------------------------------------------------------------------------- FAQ

------------------------------------------------------------------------------------------------
*/

.faq-intro p {
  @include text-larger;
}

.faq-list {
  @include col-med;
}

.faq-heading {
  border-top: 3px solid $text-dark;
}

.faq-heading::before {
  position: absolute;
  content: "ᐅ";
  font-size: 1rem;
  top: 18px;
  left: 10px;
  color: $primary;
  @media only screen and (min-width: 640px) {
      top: 19px;
  }
}

.faq-heading a {
  display: block;
  padding: 14px 0 14px 40px;
  color: $text-dark;
  position: relative;
  @media only screen and (min-width: 640px) {
    padding: 14px 0 14px 50px;
  }
}
.faq-heading a:hover {
  color: $primary;
}

.faq-content {
  transition:height 0.5s ease-out;
  height:0;
  overflow:hidden;
}

.faq-content-inner {
  border-top: 1px solid $text-dark;
  padding: 20px 20px 0 20px;
  p:last-child {
    margin-bottom: 2rem !important;
  }
}
.faq-content-inner ul {
  // font-size: 1em;
  // list-style-type: disc;
  padding: 10px 16px 22px 16px;
}

.faq-open .faq-heading:before {
  content: "ᐁ";
  top: 15px;
  left: 8px;
}

/*
-------------------------------------------------------------------------------------- FACT LIST

------------------------------------------------------------------------------------------------
*/

.about-swoop-intro {
  @include col-wide;
  text-align: center;
  // padding-bottom: 1em;
}
.about-swoop-intro p {
  @include text-larger;
}

.fact-list > ol {
  background-color: $white;
  color: $text-main;
  @include col-med;
  counter-reset: li;
  padding-left: 0;
}

.fact-list > ol > li {
  list-style: none;
  position: relative;
  margin: 0;
  padding: 1rem 2rem 1rem 3.2rem;
}
.fact-list > ol > li:before {
  content: counter(li) ")";
  counter-increment: li;
  position: absolute;
  top: 0.8rem;
  left: 1.4rem;
}

.fact-list ol p {
  font-size: 1em;
  margin-bottom: 0;
  line-height: 1.2;
}

.fact-list li:nth-child(even) {
  background-color: $bg-grey-paler;
}

/*
----------------------------------------------------------------------------------- SWOOP ADVICE

------------------------------------------------------------------------------------------------
*/

.swoop-advice-summary {
  @include col-med;
  text-align: center;
  padding-top: 36px;
}
.swoop-advice-summary p {
  @include text-larger;
  margin-bottom: 0;
}
.swoop-advice-content {
  @include col-med;
  padding-top: 36px;
}



/*
----------------------------------------------------------------------------------- PAYMENT PAGE

------------------------------------------------------------------------------------------------
*/

.mfp-zoom-in {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transform: scale(0.8);
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }

  &.mfp-bg {
    opacity: 0;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1);
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }

  /* animate out */
  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(0.8);
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

/*
--------------------------------------------------------------------------- ENQUIRY THANKS POPUP

------------------------------------------------------------------------------------------------
*/

.adv-thanks {
  text-align: center;
}

.enquiry-thanks-popup,
.white-popup.enquiry-thanks-popup {
  background-color: $bg-grey-pale;
}

.enquiry-thanks-popup .modal-header h2 {
  margin-bottom: 12px;
}
.enquiry-thanks-popup .modal-header p {
  font-size: 1em;
}

.enquiry-popup-form-intro {
  font-size: 2.6em;
  max-width: 490px;
  margin-left: auto;
  margin-right: auto;
}
.enquiry-popup-form-phone-intro {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}

.enquiry-popup-btn {
  color: $text-main;
  background-color: #b4bac6;
  font-size: 18px;
  line-height: 1.55555556;
  text-decoration: none;
  @include font-smoothing(on);
  display: block;
  padding: 8px 4px;
  margin: 0 auto 15px auto;
  max-width: 370px;
  transition: background-color 100ms linear, color 100ms linear,
    border-color 100ms linear;
}

.enquiry-popup-btn:hover,
.enquiry-popup-btn.active {
  text-decoration: none;
  color: $white;
  background-color: $bg-swoop;
}

.js-enquiry-popup-radios .radio {
  display: none;
}

.enquiry-popup-form-fields {
  max-width: 370px;
  margin: 0 auto;
}
.enquiry-popup-form-fields label {
  font-size: 1.1em;
}
.enquiry-popup-form-fields input {
  text-align: center;
}

// Enquiry typeform popup

.white-popup.enquiry-typeform-popup {
  padding: 0;
}

.white-popup.enquiry-typeform-popup .mfp-close {
  right: 20px;
}

.mfp-close-btn-in .white-popup.enquiry-typeform-popup .mfp-close {
  color: #fff;
}

// File Icons

.file-icon {
  text-indent: 20px;
  display: inline-block;
  line-height: 28px;
  position: relative;
}

.file-icon:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 26px;
  height: 28px;
  background: url("../img/swoop_doc_icon_sprite.png") 0 0 no-repeat;

  @media (-o-min-device-pixel-ratio: 5/4),
    (-webkit-min-device-pixel-ratio: 1.25),
    (min-resolution: 120dpi),
    (min-resolution: 1.25dppx) {
    background-image: url("../img/swoop_doc_icon_sprite.png");
    background-size: 280px 80px;
  }
}

.icon-doc:before,
.icon-docx:before {
  background-position: -35px 0;
}

.icon-pdf {
  padding-left: 15px;
}
.icon-pdf:before {
  background-position: -70px 0;
}
.icon-ppt:before {
  background-position: -105px 0;
}

.icon-xls:before,
.icon-xlsx:before,
.icon-csv:before {
  background-position: -140px 0;
}

.icon-link:before {
  background-position: -175px 0;
}

/*
----------------------------------------------------------------------------------------- ENQUIRY FORM

------------------------------------------------------------------------------------------------
*/
/* The checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
position: absolute;
top: 0;
left: 0;
height: 22px;
width: 22px;
background-color: #fff;
border: solid 2px #cdcfcf;
}

/* On mouse-over, add a grey background color */
.checkbox:hover input ~ .checkmark {
  border-color: #575757;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked ~ .checkmark {
  background-color: $primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.guidebook {
  input[type="checkbox"] {
      opacity: 0;
      width: 0;
      height: 0;
      &:active ~ label, &:checked ~ label {
          border-color: $primary;
          background-color: #edf2f7;
        }
  }
}

/* Customize the label (the container) */
.checkbox-label,
.icon-label {
  display: block;
  position: relative;
  padding-left: 33px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 1.1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon-label {
  margin-bottom: 73px;
}

.icon {
  position: absolute;
  top: 4px;
  left: 11px;
  height: 60px;
  width: 60px;
}

.icon-grid {
  display: grid;
  grid-template-columns: 11.5% 11.5% 11.5% 11.5% 11.5% 11.5% 11.5% 11.5%;
  grid-column-gap: 10px;
  grid-template-rows: auto;
  grid-row-gap: 18px;
  font-size: small;
  text-align: center;
}

/* On mouse-over, add a #454545 background color */
.checkbox-label:hover input ~ .checkmark {
  background-color: #454545;
}

/* When the checkbox is checked, add a #454545 background */
.checkbox-label input:checked ~ .checkmark {
  background-color: #454545;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-label input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-label .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.wildlife-select [type="checkbox"]:checked,
.wildlife-select [type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.wildlife-select [type="checkbox"]:checked + label,
.wildlife-select [type="checkbox"]:not(:checked) + label{
  position: relative;
  cursor: pointer;
}

[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}

[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #D9E3F0;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 10px;
  height: 10px;
  background: $primary;
  position: absolute;
  top: 5px;
  left: 5px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.wildlife-select [type="checkbox"]:checked + label{
  border-color: $primary;
}
.wildlife-select [type="checkbox"]:checked + label > img{
  opacity: 0.5;
}

[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.form-email-number {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.call-time {
  width: 50%;
}

.first-sur {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.enquiry-form-title {
  font-size: 1.3em;
  color: white;
}

.enquiry-form-header {
  background-color: rgb(35, 54, 82);
  text-align: center;
  padding: 42px 0px 17px 0px;
}

.form-container-inner {
  display: flex;
}

.enquiry-form-right {
  padding: 30px;
  display: flex;
  width: 360px;
  border-left: 1px solid grey;
  margin-left: 30px;
}

.enquiry-dates-flight {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 7%;
}

.enquiry-radio-label {
  font-size: 1.1em;
  margin-bottom: 4%;
  text-align: right;
}

.enquiry-radios {
  font-family: "PlantinMTStd-Regular", Times, serif;
  margin-left: 68px;
}

.enquiry-form-area-main {
  font-weight: 600;
  color: hsla(0, 0%, 0%, 0.75);
}

.enquiry-form-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid $primary;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin: 1px 75px 1px 75px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.post-form-page {
  display: grid;
  grid-template-columns: 55%;
  justify-content: center;
}

@media screen and (max-width: 648px) {
  .form-email-number {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 0px;
  }
  .call-time {
    width: 100%;
  }
  .first-sur {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 0px;
  }
  .icon-grid {
    grid-template-columns: 20% 20% 20% 20% 20%;
  }
  .icon {
    left: 16px;
  }
  .enquiry-dates-flight {
    display: block;
  }
  .enquiry-radios {
    margin-left: 0;
  }
  .enquiry-radio-label {
    text-align: inherit;
  }
  .post-form-page {
    grid-template-columns: 83%;
  }
  .enquiry-form-right {
    border-left: none;
    margin: 0 auto;
  }
  .form-container-inner {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .enquiry-form-title {
    font-size: 1.1em;
  }
  .icon-grid {
    grid-template-columns: 23% 23% 23% 23%;
  }
  .icon {
    left: 11px;
  }
}

.file-upload-input .form-control {
  width: 100%;
  float: none;
  margin-left: -1px;
  border-right: 1px solid $form-input-border;
}

// Async upload
.async-upload {
  @include clrfix;
  margin-bottom: 10px;
}

/* Progress bar */

.progress {
  background-color: $bg-grey-paler;
}

.progress-bar {
  background-color: #1ab394;
}

.progress-small,
.progress-small .progress-bar {
  height: 10px;
}

.progress-small,
.progress-mini {
  margin-top: 5px;
}

.progress-mini,
.progress-mini .progress-bar {
  height: 5px;
  margin-bottom: 0;
}

@import "enhanced"; //responsive

.cta-about {
  display: none;
}

@media (min-width: 768px) {
  .cta-enquiry {
    display: none;
  }
  .cta-about {
    display: block;
  }
}

.floating-form .h-2 {
  height: auto;
}

// YouTube lazy load styles
.youtube-embed {
  position: relative;
  padding-bottom: 56.23%;
  /* Use 75% for 4:3 videos */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 0;
}

.youtube-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: transparent;
}

.youtube-embed img {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  height: auto;
  cursor: pointer;
  -webkit-transition: 0.4s all;
  -moz-transition: 0.4s all;
  transition: 0.4s all;
}

.youtube-embed img:hover {
  -webkit-filter: brightness(75%);
}

.youtube-embed .play {
  height: 7rem;
  width: 7rem;
  left: 50%;
  top: 50%;
  margin-left: -3.5rem;
  margin-top: -3.5rem;
  position: absolute;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cstyle type='text/css'%3E .opt%7Bopacity:0.6;%7D .col-wht%7Bfill:%23FFFFFF;%7D %3C/style%3E%3Ccircle class='opt' cx='29.8' cy='29.2' r='19.3'/%3E%3Cpath class='col-wht' d='M28,34.8l7.7-5c0.4-0.3,0.4-0.9,0-1.2l-7.7-5c-0.6-0.4-1.4,0-1.4,0.6v10C26.6,34.8,27.4,35.2,28,34.8z'/%3E%3Cpath class='col-wht' d='M29.8,13.6c-8.6,0-15.6,7-15.6,15.6s7,15.6,15.6,15.6c8.6,0,15.6-7,15.6-15.6S38.4,13.6,29.8,13.6z M29.8,42.1c-7.1,0-12.9-5.8-12.9-12.9c0-7.1,5.8-12.9,12.9-12.9c7.1,0,12.9,5.8,12.9,12.9C42.6,36.4,36.9,42.1,29.8,42.1z'/%3E%3C/svg%3E") no-repeat;
  cursor: pointer;
}

figure img,
.img-fit {
  object-fit: cover;
  width: 100%;
}
figure:hover img,
article a:hover > img {
  opacity: 0.8;
  transition: opacity .5s;
  transition-timing-function: ease-out;
}

// tailwind over-ride
.mb-0 {
  margin-bottom: 0px;
}
.mr-0 {
  margin-right: 0px;
}
// hidding Anchor text
div.jumplink a[href$=hidden], a[data-ga-event*="hidden"] {
  display: none;
}
// new multi img content block style
.scroller {
  animation:arrow 4s infinite;
  -webkit-animation:arrow 4s infinite
 }
 @keyframes arrow {
  0% {
   opacity:0;
   -webkit-transform:translatex(10px);
   transform:translatex(10px)
  }
  80% {
   opacity:1;
   -webkit-transform:translatex(50px);
   transform:translatex(50px)
  }
  100% {
   opacity:0;
   -webkit-transform:translatex(50px);
   transform:translatex(50px)
  }
 }
 @-webkit-keyframes arrow {
  0% {
   opacity:0;
   -webkit-transform:translatex(10px);
   transform:translatex(10px)
  }
  80% {
   opacity:1;
   -webkit-transform:translatex(50px);
   transform:translatex(50px)
  }
  100% {
   opacity:0;
   -webkit-transform:translatex(50px);
   transform:translatex(50px)
  }
 }
 .scroller {
  animation-delay:-.9s;
  -webkit-animation-delay:-.9s
 }
 .animate-spin {
  animation: spin 1s linear infinite;
 }

 @keyframes spin {
   from {
     transform: rotate(0deg);
   }
   to {
     transform: rotate(360deg);
   }
 }

 .fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
.blue-gradient {
  background-image: linear-gradient(180deg,transparent,rgba(43 67 101));
}

