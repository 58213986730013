/*
----------------------------------------------------------------------------------------- HEADER

------------------------------------------------------------------------------------------------
*/

.header {
  position: relative;
  z-index: 20;
}
.header-inner {
  background-color: inherit;
  padding-bottom: 2px;
}

@media screen and (max-width:832px) {
  .header-inner,
  .header {
    background-color: #05143a;
    background: linear-gradient(0.1turn, #05143a, #1f2966);
  }
}

// Amazon Connect specific fix for spacing issue brought in from legacy .logo class styling.
#amazon-connect-chat-widget .logo {
  max-width: 85vw;
  margin: 0;
}

.logo {
  display: block;
  margin: 0 20px 0 10px;
  float: left;
  transition: max-width 0.3s ease-out;
}


.logo img {
  display: block;
  height: auto;
}

.small-header .logo {
  max-width: 100px;
}

.header p {
  margin-bottom: 0;
}

.header-contact {
  clear: both;
  @include clrfix;
  position: relative;
  min-height: 38px;
  // border-top: 1px solid white;
}

.ap-header-contact {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 38px;
  color: $white;
  .username {
    display: none;
  }
  .btn-logout {
    margin-top: 1em;
    left: 1em;
    width: calc(100% - 2em);
    text-align: center;
  }
}

// .header-contact .tel {
//   text-decoration: none;
//   font-size: 1.3em;
//   @include font-smoothing(on);
//   display: block;
//   line-height: 39px;
//   text-align: right;
//   margin-left: 100px;
//   overflow: hidden;
// }

.header-contact .tel span {
  white-space: nowrap;
}

// .header-cta {
//   display: none;
// }

@media screen and (min-width: $break-medium) {
  //  720/16

  .header .divider {
    display: none;
  }

  .logo {
    width: 54%;
    max-width: 530px;
    padding-right: 30px;
    border-bottom: none;
  }

  .logo img {
    margin-left: 0px;
    margin-top: 0px;
  }

  .header-contact {
    // width: 44%;
    float: right;
    padding-bottom: 0;
    clear: none;
    border-top: none;
  }

  .header-contact {
    float: right;
    padding-bottom: 0;
    clear: none;
    border-top: none;
    .username {
      display: inline-block;
      margin-top: 0.75em;
      margin-right: 1em;
      font-size: 0.8em;
    }
    .btn-logout {
      margin: 0;
      text-align: left;
      width: auto;
      left: 0;
      margin-top: 0;
    }
  }

  .ap-header-contact {
    float: right;
    padding-top: 18px;
    padding-bottom: 0;
    clear: none;
    margin: 0;
    border-top: none;
    .username {
      display: inline-block;
      margin-top: 0.75em;
      margin-right: 1em;
      font-size: 0.8em;
    }
    .btn-logout {
      margin: 0;
      text-align: left;
      width: auto;
      left: 0;
      margin-top: 0;
    }
  }

  .header-contact a {
    float: right;
  }
  // .header-contact .header-cta {
  //   display: block;
  // }
  .header-contact .tel {
    font-size: 1.3em;
    line-height: 44px;
    margin: 0;
    text-align: left;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
  }

  .header-main .row {
    position: relative;
  }
  .header-inner,
  .header-new {
    position: relative;
  }
  .header-inner .float-header {
    background: none;
    border-bottom: none;
  }
}

/* Header Basic */

.header-basic .header-contact .tel {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}

@media screen and (min-width: $break-medium) {
  //  720/16

  .header-basic .logo {
    max-width: 380px;
    padding-top: 26px;
    margin-bottom: 26px;
  }
  .header-basic .logo img {
    margin-bottom: 0;
  }
  .header-basic .header-contact {
    padding-top: 18px;
  }

  .header-basic .header-contact .tel {
    padding-right: 0;
  }
}

@media screen and (min-width: 768px) {
  .logo {
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 0;

    max-width: 530px;
  }

  .logo img {
    margin-top: 0;
  }

  .header-contact {
    float: right;
    padding-bottom: 0;
    clear: none;
  }
}

@media screen and (min-width: 1024px) {
  .logo {
    max-width: 491px;
  }
}

@media screen and (max-width: 1024px) {
  .header-contact {
    .tel {
      margin-left: 0;
    }
  }
}

/* Header Fixed */

// @media screen and (min-width: $break-medium) {
//   .header-fixed {
//     position: fixed;
//     top: 0;
//     width: 100%;
//   }
//   .header-basic-spacer {
//     height: 80px;
//   }
// }

// @media screen and (max-width: 450px) {
//   .header-contact {
//     width: 96%;
//   }
// }
