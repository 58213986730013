/*
--------------------------------------------------------------------------------- COOKIE WARNING

------------------------------------------------------------------------------------------------
*/

.cookie-warning-wrapper {
  z-index: 9001;
  position: relative;
}

@keyframes cookieSlideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.cookie-warning {
  font-size: 0.875em;
  padding: 12px 15px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(5, 20, 58, 1);
  color: $white;
  transform: translateY(100%);
  animation: cookieSlideUp 0.6s 1s ease-out;
  animation-fill-mode: forwards; //retain the computed values set by the last keyframe
}

.cookie-warning p {
  margin-bottom: 0;
  font-size: 1em;
  display: inline-block;
}

.cookie-warning-action {
  width: fit-content;
  display: inline-block;
}
.cookie-warning .btn {
  margin: 0;
  padding: 0;
  font-size: 1em;
  line-height: 1em;
  font-weight: 700;
  padding: 6px 8px;
}

.cookie-warning-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: $break-tablet) {
  .cookie-warning {
    padding-left: 30px;
    padding-right: 30px;
  }

  .cookie-warning-action {
    text-align: right;
  }
}

/* cookie consent spacer */

.cookie-consent-spacer {
  height: 80px;
  background-color: #05143A;
  @media screen and (min-width: $break-tablet) {
    height: 50px;
  }
}
.cookie-dismissed .cookie-consent-spacer {
  display: none;
}

/* DISMISSED */
.cookie-dismissed .cookie-warning-wrapper {
  display: none;
}
