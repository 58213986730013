.rounded, .rounded-lg, .rounded-md, .full-card a.rounded, a.rounded.half-card, .content-block .content-block-img, .video-container, .swiper.gallery .swiper-slide, .illustrated-guide img, a.rounded-lg, .map-canvas {
border-radius: 20px;
}
.swiper.gallery.full-screen .swiper-slide {
border-radius: 0;
}
.btn, button.rounded, button.rounded-lg, button > .rounded, a.rounded, a.rounded-md, input.rounded, h3.rounded, a > div > dd span.rounded, #prices_and_departures div.cursor-pointer.rounded, input.rounded-md, input.rounded, textarea.rounded, .c-tour-map-desktop.rounded, select.rounded, select.rounded-md, p.rounded {
border-radius: .25rem;
}
blockquote.rounded-md {
border-radius: 10px;
}
.sm\:bg-orange-500, .sm\:bg-orange-600, .sm\:bg-orange-700, .bg-orange-500, .bg-orange-600, .bg-orange-700, .btn, button, .hover\:bg-orange-500:hover, .hover\:bg-orange-600:hover, .hover\:bg-orange-700:hover, .btn-secondary:focus, .btn-secondary:hover {
--tw-bg-opacity:1;
background-color:#FF6224
}
.sm\:text-orange-500, .sm\:text-orange-600, .sm\:text-orange-700, .text-orange-500, .text-orange-600, .text-orange-700, .hover\:text-orange-500:hover, .hover\:text-orange-600:hover, a:hover.btn, a:hover.btn.btn-sm, button:hover, a:hover.btn-inner {
color:#FF6224
}
button:hover.bg-orange-600 > svg {
	fill:#FF6224
}
.bg-gray-900 {
	background-color:#200e11
}
a:hover.btn, a:hover.btn.btn-sm, button:hover.bg-orange-600, span:hover.btn {
	background-color:#fff !important;
	border-color: #FF6224 !important;
	color: #FF6224 !important;
}
.text-gray-900 {
	color:#200e11
}
.bg-swoop-secondary, .bg-swoop-secondary.more-content::before, .review-summary.bg-swoop-secondary::before, .bg-blue-800, .bg-blue-900 {
background-color: #05143A;
}
button.sky-blue, .hover\:bg-blue-700:hover {
	background-color:#008bff
}
button:hover.sky-blue {
	color: #fff !important;
	background-color:#1f2966 !important;
}
.js-swiper-cta {
background-color: transparent;
}
.content-block .content-block-img, .video-container, .video-container, .swiper.gallery .swiper-slide {
overflow: hidden;
}
.video-container, .gallery-wrapper .row {
max-width: 1184px;
}