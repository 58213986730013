.c-tour {
  &-map {
    &-desktop {
      width: 100%;
      max-width: 278px;
    }
    &-mobile {
      width: 100%;
      height: 300px;
      background-position: center;
      background-size: cover;
      bottom: -5px;
      margin: -5px 0 0 0;
    }
  }

  &-sidebar {
    width: 100%;
    max-width: 100%;

    @media (min-width: 1024px) {
      max-width: 278px;
    }

    &-map {
      width: 100%;
      max-width: 33%;
    }
  }

  &-item {
    &-trip {
      &-footer {
        min-height: 61px;
      }
    }
  }
}
