html {
    scroll-behavior: smooth;
}
#call-schedule {
    .no-padding {
        padding: 0 !important;
    }
    
    .call-schedule-button {
        background: linear-gradient(270deg, #fee1db, #fff4d6);
        background-size: 400% 400%;
        -webkit-animation: CallScheduleAnimationButton 6s ease infinite;
        -moz-animation: CallScheduleAnimationButton 6s ease infinite;
        -o-animation: CallScheduleAnimationButton 6s ease infinite;
        animation: CallScheduleAnimationButton 6s ease infinite;
        padding: 1px 3px 1px 0;
        border: none;
        outline: none;
        text-transform: initial;
        bottom: 35%;
        @media (max-width: 650px) {
            bottom: 0;
        }
    }
}

@-webkit-keyframes CallScheduleAnimationButton {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes CallScheduleAnimationButton {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-o-keyframes CallScheduleAnimationButton {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes CallScheduleAnimationButton {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

