/*
------------------------------------------------------------------------------------------------
  EMBER FRAMEWORK SITE FRONT CSS - _site_variables.scss - SASS VARIABLES AND MIXINS
------------------------------------------------------------------------------------------------
  Created:  01-03-2015
  Author:   Ember Interactive (www.emberinteractive.co.uk)
  Website:  Ember Interactive Limited
------------------------------------------------------------------------------------------------
*/

/* Swoop Colours */

$white: #ffffff;

$primary: #FF6224; // ORANGE
$primary-dark: #740f35;
$primary-hover: #740f35; // DARKER ORANGE
$primary-pale: #FF6224;

$link-primary: #FF6224; // readble ORANGE

$text-dark: #200E11;
$text-main: #200E11;
$text-pale: #515151;
$text-paler: #7b7b7b;
$text-placeholder: #a9a9a9;

$bg-swoop: #05143a; // DARK BLUE
$bg-swoop-secondary: #05143a; // PALER BLUE
$bg-swoop-secondary-paler: #05143a; // PALER BLUE

$bg-press: #CDF1FF; // PALE GREEN
$bg-customer: #73CDD1; // PALE BLUE
$bg-partner: #0088ff; // DARK GREEN

$bg-grey-paler: #f7fafc;
$bg-grey-pale: #edf2f7;
$bg-grey-mid: #dbe2e7;
$bg-grey-dark: #cbd5e0;

$form-input-border: #cecfcf;
$form-input-bg: #ffffff;
$form-input-focus-bg: #fafafc;

$border-grey-pale: #d6d6d6;
$border-grey-mid: #8c8c8c;

$danger: #a20000; // red
$success: #5ab54a; // green

$deal: #c70e0e;

$placeholder-bg: #f2fbff;
$placeholder-border: #b6bcbf;

/* Breakpoints */

$break-landscape: 30em; //  496px/16
$break-tablet: 48em; //  768px/16
$break-desktop: 64em; // 1024px/16
$break-wide: 80em; // 1280px/16

// New more standard breakpoints
$break-small: 640px;
$break-medium: 768px;
$break-large: 1024px;
$break-xl: 1280px;

/* Mixins */

@mixin appearance($app) {
  -webkit-appearance: $app;
  -moz-appearance: $app;
  -ms-appearance: $app;
  -o-appearance: $app;
  appearance: $app;
}

/* Reset all LIs that aren't in body content */
@mixin li-reset() {
  padding: 0;
  text-indent: 0;
  &:before {
    content: "";
    display: none;
    background: none;
    width: auto;
    height: auto;
    padding: 0;
  }
}

/* Override the bourbon mixin as ie8 doesn't support :: */
@mixin clrfix() {
  &:after {
    clear: both;
    content: "";
    display: table;
  }
}

/* Add icon to element */

@mixin icon() {
  // content: "";
  // display: inline-block;
  // background: url("../img/swoop-icon-sprite.png?v=1.4") 0 0 no-repeat;
  // width: 20px;
  // height: 20px;
  // @media (-o-min-device-pixel-ratio: 5/4),
  //   (-webkit-min-device-pixel-ratio: 1.25),
  //   (min-resolution: 120dpi),
  //   (min-resolution: 1.25dppx) {
  //   background-image: url("../img/retina/swoop-icon-sprite.png?v=1.4");
  //   background-size: 1740px 150px;
  // }
}

/* Hide text within icon link */
@mixin icon-hide() {
  display: block;
  overflow: hidden;
  word-wrap: normal; /* WORD WRAP IS IMPORTANT!! */
  white-space: nowrap;
  text-indent: 101%;
}

/* Switch Font smoothing settings */
@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/* Screen reader only text */
@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/* Column width */

@mixin col-wide() {
  max-width: 930px;
  margin: 0 auto;
}

@mixin col-med() {
  max-width: 740px;
  margin: 0 auto;
}

/* Text Sizes */
@mixin text-smaller() {
  font-size: 0.875rem;
  @media screen and (min-width: 768px) {
    //  720/16
    font-size: 1rem;
  }
}

@mixin text-larger() {
  font-size: 1rem;
  margin-bottom: 26px;
  @media screen and (min-width: 768px) {
    //  720/16
    font-size: 1.375rem;
    margin-bottom: 36px;
  }
}
